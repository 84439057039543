import React from "react";

import classes from "./VQrCodeCard.module.scss";
import VQrCode from "../VQrCode/VQrCode";

type Props = {
  text: string;
  link: string;
};

const VQrCodeCard: React.FC<Props> = ({ text, link }) => {
  return (
    <div className={classes["container"]}>
      <VQrCode link={link} size={140} />
      <p className={classes["text"]}>{text}</p>
    </div>
  );
};

export default VQrCodeCard;
