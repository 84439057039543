import React from "react";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import classes from "./Error.module.scss";
import Logo from "../../assets/images/logo/Logo.png";



const Error: React.FC = () => {
  const { t, i18n } = useTranslation();
  const queryParameters = new URLSearchParams(window.location.search)
  const code = queryParameters.get("code")
  const lang = queryParameters.get("lang")

  if (lang && lang === i18n.language) {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang === "ru" ? "ru" : "ro";
  }

  return (
    <div className={classes["container"]}>
      <div className={classes["box"]}>
        <section className={classes["header"]}>
          <img src={Logo} alt={"FinComBankLogo"} width={169} height={28} />
          <LanguageSwitcher />
        </section>


        <section className={classes.info}>
          <p className={classes.title}>{t("error.title")}</p>
          <p className={classes.text}>
            {t("error.message")}
          </p>
          <p className={classes.text}>
            {t("error.message2")}
            {code &&
                <span className={classes.code}>{t("error.code")}</span>
            }
          </p>

        </section>
      </div>
    </div>
  );
};

export default Error;
