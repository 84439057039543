import React, { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";

type Props = {
  link: string;
  size?: number;
  color?: string;
  bgColor?: string;
};

const VQrCode: React.FC<Props> = ({
  link,
  size = 100,
  color = "#4bafb1",
  bgColor = "transparent",
}) => {
  const ref = useRef<any>(undefined);

  const qrCode = new QRCodeStyling({
    type: "svg",
    width: size,
    height: size,
    dotsOptions: {
      color: color,
      type: "extra-rounded",
    },
    backgroundOptions: {
      color: bgColor,
    },
  });

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: link,
    });
  }, [link]);

  return <div ref={ref} />;
};

export default VQrCode;
