import React from "react";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";
import VButtonLink from "../../components/VButtonLink/VButtonLink";

import classes from "./Home.module.scss";

import Logo from "../../assets/images/logo/Logo.png";
import VQrCode from "../../components/VQrCodeCard/VQrCodeCard";

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes["container"]}>
      <div className={classes["box"]}>
        <section className={classes["header"]}>
          <img src={Logo} alt={"FinComBankLogo"} width={169} height={28} />
          <LanguageSwitcher />
        </section>

        <section className={classes["buttons"]}>
          <VButtonLink href={"https://new.fincompay.com"}>
            {t("home.physicalPersons")}
          </VButtonLink>

          <VButtonLink
            href={"https://jur.fincompay.com:7002/ifobsClientFinCom"}
          >
            {t("home.business")}
          </VButtonLink>
        </section>

        <section className={classes["QRSection"]}>
          <p className={classes["text"]}>{t("home.scanQrCode")}</p>

          <div className={classes["qrCodes"]}>
            <VQrCode
              text={t("home.forYou")}
              link={"https://mob.fincompay.com/QR"}
            />
            <VQrCode
              text={t("home.forBusiness")}
              link={"https://qrco.de/bdAWTF"}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
