import React from "react";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";

import classes from "./Login.module.scss";

import Logo from "../../assets/images/logo/Logo.png";
import VQrCode from "../../components/VQrCode/VQrCode";
import IconPhone from "../../assets/icons/IconPhone";

/*
 Страница авторизации для MOB через QR
 */
const Login: React.FC = () => {
  const { t } = useTranslation();
  let locationUrl = window.location.href;

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <section className={classes.header}>
          <img src={Logo} alt={"FinComBankLogo"} width={169} height={28}/>
          <LanguageSwitcher/>
        </section>

        <section className={classes.info}>
          <p className={classes.text}>{t("login.forFinish")}</p>
          <p className={classes.text}>
            <span>{t("login.scanQrCodeWithDevice")}</span>
          </p>
          <VQrCode size={200} link={locationUrl} />
        </section>

        <div className={classes.divider}></div>

        <section className={classes.newApp}>
        <p className={classes.text}>{t("login.scanToDownload")}</p>
          <VQrCode size={180} link="https://mob.fincompay.com/QR" />
        </section>

        <section className={classes.question}>
          <p className={classes.text}>{t("login.haveQuestion")}</p>
          <div className={classes.num}>
            <IconPhone width={13} height={13}/>
            <span>022</span> 269 999
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
