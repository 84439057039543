import React from "react";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";

import classes from "./Deprecated.module.scss";

import Logo from "../../assets/images/logo/Logo.png";
import IOS from "../../assets/images/ios.png";
import Android from "../../assets/images/android.png";

import VQrCode from "../../components/VQrCode/VQrCode";

const Deprecated: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <section className={classes.header}>

            <img src={Logo} alt={"FinComBankLogo"}  />
            <LanguageSwitcher />
          </section>
          <section className={classes.info}>
            <p className={classes.text}>{t("deprecated.info")}</p>
          </section>
        </div>

        <section className={classes.content}>
          <p className={classes.contentTitlu}>{t("deprecated.downloadApp")}</p>

          <div className={classes.contentWrapper}>
            <div className={classes.facilitati}>
              <ul className={classes.list}>
                {Array(10)
                  .fill("")
                  .map((item: string, idx: number) => (
                    <li key={idx}>{t(`deprecated.list.a${idx + 1}`)}</li>
                  ))}
              </ul>
            </div>

            <div className={classes.download}>
              <p className={classes.titlu}>{t("deprecated.scanQr")}</p>

              <div className={classes.qrWrapper}>
                <VQrCode
                  link={"https://mob.fincompay.com/QR/"}
                  bgColor="white"
                  size={180}
                  color="black"
                />
              </div>
              <div className={classes.store}>
                <img src={IOS} alt="GooglePlay" />
                <img src={Android} alt="AppStore" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Deprecated;
