import {Route, Routes, Navigate} from "react-router-dom";

import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Error from "../pages/Error/Error";
import Deprecated from "../pages/Deprecated/Deprecated";

type Props = {};

const Routing = (props: Props) => {
    let locationUrl = window.location.href;

    return (
        <Routes>
            {locationUrl.search("new.fincompay") > 0 &&
				<Route path="/" element={<Deprecated/>}/>
            }
            {locationUrl.search("new.fincompay") === -1 &&
				<Route path="/" element={<Home/>}/>
            }

            <Route path="/deprecated" element={<Deprecated/>}/>
            <Route path="/error" element={<Error/>}/>
            <Route path="/ob/login" element={<Login/>}/>
            <Route path="*" element={<Navigate replace to="/" />}/>

        </Routes>
    );
};

export default Routing;
