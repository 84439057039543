import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ruTranslation from './locales/ru.json';
import roTranslation from './locales/ro.json';

declare global {
  interface Window {
    i18next: typeof i18n;
  }
}

const LANGUAGE_KEY = 'selectedLanguage';

const getStoredLanguage = () => {
  return localStorage.getItem(LANGUAGE_KEY) || 'ro';
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru: {
        translation: ruTranslation,
      },
      ro: {
        translation: roTranslation,
      }
    },
    lng: getStoredLanguage(),
    fallbackLng: 'ro',
    interpolation: {
      escapeValue: false,
    }
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem(LANGUAGE_KEY, lng);
});

window.i18next = i18n;

export default i18n;
