import React from "react";
import Routing from "./routing";
import "./i18n/i18n";

import "./assets/styles/colors.module.scss";
import "./assets/styles/fonts.scss";
import "./assets/styles/globals.scss";

function App() {
  return (
    <Routing />
  );
}

export default App;
