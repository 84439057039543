import React, { PropsWithChildren } from "react";

import classes from "./VButtonLink.module.scss";
import { Link } from "react-router-dom";

type Props = {
  href: string;
  className?: string;
};

const VButtonLink: React.FC<PropsWithChildren<Props>> = ({
  children,
  href,
  className,
}) => {
  return (
    <Link
      to={href}
      className={`${classes["link"]} ${className ? className : ""}`}
    >
      {children}
    </Link>
  );
};

export default VButtonLink;
