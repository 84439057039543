import React from "react";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
};

const IconPhone: React.FC<Props> = ({
  width = 10,
  height = 10,
  fill = "black",
  className = "",
}) => {
  return (
    <svg
      stroke="currentColor"
      className={className}
      fill={fill}
      strokeWidth="0"
      viewBox="0 0 24 24"
      height={`${height}px`}
      width={`${width}px`}
      xmlns="http://www.w3.org/2000/svg"
      data-darkreader-inline-fill=""
      data-darkreader-inline-stroke=""
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
    </svg>
  );
};

export default IconPhone;
